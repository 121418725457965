<template>
  <v-card>
    <v-card-text class="pa-5 border-bottom">
      <validation-observer ref="observer">
        <v-row class="justify-center d-flex py-10">
          <v-data-table
            :headers="headers"
            :items="documentosList"
            sort-by="descricao"
            class="border"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Imagens adicionadas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="abrirArquivo"
                  >Nova imagem</v-btn
                >
                <input
                  type="file"
                  ref="file"
                  @change="changeArquivo"
                  style="display: none"
                  accept=".jpg,.png"
                />
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="excluirDocumento(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:no-data>
              <span>Sem documentos adicionados</span>
            </template>
          </v-data-table>
        </v-row>
      </validation-observer>
    </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <buttons-cadastro @close="close" @save="sendDocs" />
      </v-card-actions>  
  </v-card>
</template>

<style lang="scss">
.button-submit-green {
  background-color: #00aa9e !important;
  color: white !important;
}
</style>

<script>
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'
import EmpresaApi from '@/services/tenants/empresa'
import FileApi from '@/services/files/index'
import { mapActions } from 'vuex'
export default {
  components: { ButtonsCadastro },
  name: 'EtapaDocumentos',
  props: {
    value: {
      type: Object,
      required: true,
    },
    documentos: Array,
    documentosList: Array,
  },
  watch: {
    value(val) {
      Object.assign(this.empresa, val)
      this.$forceUpdate()
    },
  },
  data() {
    return {
      empresa: this.value,
      editandoAgendamento: false,
      verificador: false,
      documento_id: 0,
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'description',
        },
        { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
      ],
    }
  },
  methods: {
    close(){},
    ...mapActions('template', ['errorMessage']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    created() {
    },
    async sendDocs() {
      const documentos = this.documentos

      let names = []
      let anexos = []
      let errors = {}

      if (this.verificador == true) {
        this.empresa.anexo = [];
        await EmpresaApi.updateEmpresa(this.empresa.public_id, {
                ...this.empresa
              })
        this.verificador = false;
      }
      
      await Promise.all(
        // Passa por todos os documentos selecionados
        documentos.map(async (documento) => {
          // Se o documento não tiver a necessidade de enviar
          if (!documento.necessity_send) {
            // populando a variavel names com o documento.attachment_key
            names.push(documento)
            return
          }
            
            // Inicializa uma variavel
            let formData = new FormData()
            //formData esta sendo preenchida com descrição e file de documento
            formData.append('description', documento.name)
            formData.append('file', documento)
            try {
              //documentosList vai percorrer data
              this.documentosList.forEach((data) => {
                  // se dentro do data tiver attachment_key
                  if(data.attachment_key){
                    //a variavel anexos e names vão ser preenchida com o data.attachment_key
                    anexos.push(data.attachment_key)
                    names.push(data)
                  } 
              })

              let data = await FileApi.postImagensEmpresa(formData)
              if (data.attachment_key) {
                anexos.push(data.attachment_key)
                names.push(data)
              }
              this.empresa.anexos = anexos

              await EmpresaApi.updateEmpresa(this.empresa.public_id, {
                ...this.empresa
              })

              this.empresa.anexos = names

              this.close()
            } catch (error) {
              if (error.response) {
                errors[documento.name] = error.response.data
                return
              }
              if (error.message) {
                errors[documento.name] = error.message
                return
              }
              errors[documento.name] = error
            }
        })
      )

      // Limpa o campo para não causar duplicidade 
      while (this.documentos.length) this.documentos.pop();
      
      if (Object.entries(errors).length) {
        this.errorMessage(errors)
      }
      this.$emit('setNames', names)
      this.successMessage('Alteração salva com sucesso!')
    },

    excluirDocumento(documento) {
      let names = []
      //Joga o verificador pra true pra fazer o update da empresa
      this.verificador = true

      this.documentosList.forEach((data) => { names.push(data) })
      this.$emit('setNames', names)

      if (documento.id) {
        const indexDocList = this.documentosList.indexOf(
          this.documentosList.filter((doc) => doc.id == documento.id)[0]
        )
        const indexDoc = this.documentos.indexOf(
          this.documentos.filter((doc) => doc.id == documento.id)[0]
        )

        this.$emit('remove-documento', this.documentosList[indexDocList])

        if (indexDoc > -1 && indexDocList > -1) {
          this.documentosList.splice(indexDocList, 1)
          this.documentos.splice(indexDoc, 1)
        }
      } 
      else 
      {
        
        const indexDocList = this.documentosList.indexOf(
          this.documentosList.filter(
            (doc) => doc.public_id == documento.public_id
            )[0]
            )
        
        const indexDoc = this.documentos.indexOf(
          this.documentos.filter(
            (doc) => doc.public_id == documento.public_id
          )[0]
        )

        this.$emit('remove-documento', this.documentosList[indexDocList])
        
        
        if (indexDoc > -1) {
          this.documentos.splice(indexDoc, 1)
        }
        if (indexDocList > -1) {
          this.documentosList.splice(indexDocList, 1)
        }
      }
    },

    setDocumentosList(documentos) {
      this.documentosList = [...documentos]
    },

    abrirArquivo() {
      this.$refs['file'].click()
    },

     changeArquivo(e) {
      // Valida se já possui imagem, como é logo não pode conter mais do que uma
      if (this.documentosList.length > 0){
        this.errorMessage('Já existe um arquivo, favor excluir o arquivo existente primeiro.')
        return
      }

       const files = e.target.files

       //Limpa o array
       while (this.documentos.length) this.documentos.pop();

       // valida se o documento ja existe na listagem
       if (this.documentosList.find((doc) => doc.description === files[0].name) == undefined) {
        this.documento_id += 1

        this.documentosList.forEach((data) => {
          Object.keys(data).forEach((key) => {
            if (data[key] == this.documento_id) {
              this.documento_id += 1
            }
          })
        })

        files[0].id = this.documento_id
        this.documentosList.push({
          description: files[0].name,
          id: this.documento_id,
          necessity_send: true,
        })
        files.forEach((file) => {
          this.documentos.push(file)
          this.documentos[this.documentos.length - 1].necessity_send = true
        })
      }
      else{
        this.errorMessage('Arquivo ja existe!')
      }

     },
  },
}
</script>
