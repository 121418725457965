<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Sobre a empresa
      </h3>
      <span class="red--text" v-if="!novaEmpresa"
        >Dados editáveis apenas pelo administrador da empresa</span
      >
    </v-card-text>
    <validation-observer ref="observer">
      <v-form :readonly="!novaEmpresa">
        <v-card-text class="pa-5 border-bottom">
          <v-row>
            <v-col cols="12" sm="6" lg="6" class="pt-0">
              <validation-provider
                v-slot="{ errors }"
                name="Razão Social"
                rules="required"
              >
                <v-text-field
                  label="Razão Social"
                  :error-messages="errors"
                  v-model="empresa.trade_name"
                  :disabled="disabled"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Nome Fantasia"
                rules="required"
              >
                <v-text-field
                  label="Nome Fantasia"
                  :error-messages="errors"
                  v-model="empresa.business_name"
                  :disabled="disabled"
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6" lg="6" class="pt-0">
              <validation-provider
                v-slot="{ errors }"
                name="CNPJ"
                rules="required|cpfOuCnpjValido"
              >
                <v-text-field
                  :label="
                    cpfValidator.isValid(empresa.cpfOrCnpj)
                      ? 'CPF'
                      : cnpjValidator.isValid(empresa.cpfOrCnpj)
                      ? 'CNPJ'
                      : 'Inválido'
                  "
                  :readonly="disabled"
                  :error-messages="errors"
                  v-model="empresa.cpfOrCnpj"
                  :disabled="disabled"
                  v-mask="['##.###.###/####-##', '###.###.###-##']"
                />
              </validation-provider>
              <v-text-field
                :disabled="disabled"
                label="IE"
                v-model="empresa.ie"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pa-5">
          <h3
            class="subtitle blue-grey--text text--darken-2 font-weight-regular"
          >
            Endereço
          </h3>
          <v-row class="mt-3">
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="CEP"
                rules="required"
              >
                <v-text-field
                  :error-messages="errors"
                  label="CEP"
                  required
                  v-mask="['#####-###']"
                  v-model="empresa.zip_code"
                  :disabled="disabled"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Estado"
                rules="required"
              >
                <v-autocomplete
                  :error-messages="errors"
                  label="Estado"
                  required
                  :items="estados"
                  v-model="empresa.state"
                  :disabled="disabled"
                  item-text="nome"
                  item-value="sigla"
                  return-object
                  @change="handleGetCidades"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Cidade"
                rules="required"
              >
                <v-autocomplete
                  :error-messages="errors"
                  label="Cidade"
                  required
                  :items="cidades"
                  v-model="empresa.city"
                  :disabled="disabled"
                  item-text="nome"
                  item-value="nome"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Bairro"
                rules="required"
              >
                <v-text-field
                  :error-messages="errors"
                  label="Bairro"
                  v-model="empresa.district"
                  required
                  :disabled="disabled"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Endereço"
                rules="required"
              >
                <v-text-field
                  :error-messages="errors"
                  required
                  label="Endereço"
                  v-model="empresa.address"
                  :disabled="disabled"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Número"
                rules="required|max:10"
              >
                <v-text-field
                  :error-messages="errors"
                  required
                  label="Número"
                  type="number"
                  v-model="empresa.numero"
                  :disabled="disabled"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Complemento"
                rules="max:50"
              >
                <v-text-field
                  :error-messages="errors"
                  label="Complemento"
                  v-model="empresa.complemento"
                  :disabled="disabled"
                />
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Telefone"
                rules="required"
              >
                <v-text-field
                  :error-messages="errors"
                  label="Telefone"
                  required
                  v-model="empresa.phone"
                  :disabled="disabled"
                  v-mask="['(##) #####-####', '(##) ####-####']"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-checkbox
                v-model="empresa.gate"
                label="Gate"
                class="ma-0 pa-0"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import { cpf, cnpj } from 'cpf-cnpj-validator'

import { validationMixin } from 'vuelidate'

import UtilsApi from '@/services/utils/utils.api'

export default {
  mixins: [validationMixin],

  props: {
    disabled: Boolean,
    empresaEdita: Object,
    value: {
      type: Object,
      required: true,
    },
    novaEmpresa: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      empresa: this.value,
      estados: [],
      cidades: [],
      cpfValidator: cpf,
      cnpjValidator: cnpj,
    }
  },
  watch: {
    'value.state': {
      async handler() {
        const { sigla } = this.value.state
        if (sigla) {
          await this.handleGetCidades(
            this.estados.find((estado) => estado.sigla === sigla)
          )
        }
      },
    },
  },
  methods: {
    async getEstados() {
      this.estados = await UtilsApi.getEstadosBrasil()
    },

    async handleGetCidades(estado) {
      const { municipios } = await UtilsApi.getCidadesEstado(estado.id)

      this.cidades = municipios
    },

    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid

      return isValid
    },
  },
  async created() {
    await this.getEstados()
  },
}
</script>
