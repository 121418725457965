<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Sobre a empresa
      </h3>
    </v-card-text>
    <validation-observer ref="observer">
      <v-form>
        <v-card-text class="pa-5 border-bottom">
          <v-row>
            <v-col cols="12" sm="6" lg="6" class="pt-0">
              <validation-provider
                v-slot="{ errors }"
                name="Nome Fantasia"
                rules="required"
              >
                <v-text-field
                  label="Nome Fantasia"
                  :error-messages="errors"
                  v-model="empresa.business_name"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Razão Social"
                rules="required"
              >
                <v-text-field
                  label="Razão Social"
                  :error-messages="errors"
                  v-model="empresa.trade_name"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6" lg="6" class="pt-0">
              <validation-provider
                v-slot="{ errors }"
                name="CNPJ/CPF"
                rules="required|cnpjValido|cnpjNaoEmpresaAtual"
              >
                <v-text-field
                  label="CNPJ/CPF"
                  :error-messages="errors"
                  v-model="empresa.cnpj"
                  v-mask="['##.###.###/####-##', '###.###.###-##']"
                />
              </validation-provider>
              <v-text-field label="IE" v-model="empresa.ie"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pa-5">
          <h3
            class="subtitle blue-grey--text text--darken-2 font-weight-regular"
          >
            Endereço
          </h3>
          <v-row class="mt-3">
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="Endereço"
                v-model="empresa.address"
              ></v-text-field>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Número"
                rules="max:10"
              >
                <v-text-field
                  :error-messages="errors"
                  label="Número"
                  v-model="empresa.numero"
                  type="number"
                />
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="Bairro"
                v-model="empresa.district"
              ></v-text-field>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Complemento"
                rules="max:50"
              >
                <v-text-field
                  :error-messages="errors"
                  label="Complemento"
                  v-model="empresa.complemento"
                />
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="Cidade"
                v-model="empresa.city"
              ></v-text-field>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="CEP"
                v-mask="['#####-###']"
                v-model="empresa.zip_code"
              ></v-text-field>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-select
                label="Estado"
                v-model="empresa.state"
                :items="estados"
                item-text="nome"
                item-value="sigla"
              ></v-select>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="Telefone"
                v-model="empresa.phone"
                v-mask="['(##) #####-####', '(##) ####-####']"
              ></v-text-field>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-checkbox
                v-model="empresa.gate"
                label="Gate"
                class="ma-0 pa-0"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <buttons-cadastro @close="close" @save="salvarEmpresa" />
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

import { validationMixin } from 'vuelidate'
import { cnpj } from 'cpf-cnpj-validator'

import UtilsApi from '@/services/utils/utils.api'

import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'
import EmpresaApi from '@/services/tenants/empresa'
export default {
  components: { ButtonsCadastro },

  mixins: [validationMixin],

  props: {
    cliente: Object,
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      empresa: this.value,
      estados: [],
    }
  },
  watch: {
    value(val) {
      Object.assign(this.empresa, val)
      this.$forceUpdate()
    },
  },
  methods: {
    ...mapActions('template', ['successMessage', 'errorMessage']),

    async getEstados() {
      this.estados = await UtilsApi.getEstadosBrasil()
    },
    close() {
      this.$emit('close')
    },
    async salvarEmpresa() {
      try {
        await EmpresaApi.updateEmpresa(this.empresa.public_id, {
          ...this.empresa,
          cnpj: cnpj.strip(this.empresa.cnpj),
        })
        this.successMessage('Empresa atualizada com sucesso')
        this.close()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
  created() {
    this.getEstados()
  },
}
</script>

<style>
</style>