<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <div class="mt-4">
      <v-card-title v-if="canList">
        Filtro
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
          @change="getSearch"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :server-items-length="empresas.count"
        item-key="public_id"
        class="border"
        :footer-props="{
          itemsPerPageAllText: `Todos (${empresas.count})`,
          itemsPerPageOptions: [pageSize].concat(
            [15, 50, 100, 500, 1000, 2000].filter((el) => el != pageSize)
          ),
        }"
        :page.sync="paginaAtual"
        :items-per-page="pageSize"
        :options.sync="options"
        @update:page="$emit('handleGetEmpresas', $event)"
        @update:items-per-page="$emit('handleGetEmpresas', $event)"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Empresas registradas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon
              v-if="canList"
              medium
              @click="handleGetEmpresas"
              title="Atualizar"
              >mdi-refresh</v-icon
            >
            <v-spacer></v-spacer>
            <v-btn
              v-if="allowedToAdd && canCreate"
              color="primary"
              dark
              class="mb-2"
              @click="adicionarEmpresa"
              >Nova empresa</v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:[`item.cnpj`]="{ item }">
          <span v-if="item.cpf">
            {{ item.cpf | VMask('###.###.###-##') }}
          </span>
          <span v-else>
            {{ item.cnpj | VMask('##.###.###/####-##') }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="canUpdate"
            title="Editar Empresa"
            small
            class="mx-1"
            @click="editItem(item)"
            >mdi-pencil</v-icon
          >
          <v-icon
            v-if="canDelete"
            title="Excluir Empresa"
            small
            class="mx-1"
            @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
          <router-link
            :to="{ name: 'Colaboradores', params: { empresa: item } }"
          >
            <v-icon
              v-if="canUpdate"
              title="Visualizar Colaboradores"
              small
              class="mx-1"
              >mdi-account-multiple</v-icon
            >
          </router-link>
          <confirm-dialog @confirm="confirmDelete" />
        </template>
        <template #no-data v-if="!canList">
          <span class="red--text"
            >Você não tem permissão para visualizar as informações desta
            tela</span
          >
        </template>
      </v-data-table>
    </div>
    <v-snackbar v-model="snackbar" top color="error" elevation="12">
      {{ errorMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Fechar </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import TenantsApi from '@/services/tenants'

export default {
  name: 'ListarEmpresas',

  components: { ConfirmDialog },
  data() {
    return {
      snackbar: false,
      errorMsg: '',
      dialog: false,
      search: '',
      empresas: [],
      options: {},
      paginaAtual: 1,
      loading: false,
      headers: [
        {
          text: 'Empresa',
          align: 'start',
          value: 'trade_name',
        },
        { text: 'Nome fantasia', value: 'business_name' },
        { text: 'CNPJ/CPF', value: 'cnpj' },
        { text: 'Estado', value: 'state' },
        { text: 'Cidade', value: 'city' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        first_name: '',
        last_name: '',
      },
      defaultItem: {
        first_name: '',
        last_name: '',
      },
    }
  },
  props: {
    pageSize: {
      typeof: Number,
      default: 10,
    },
  },
  computed: {
    //...mapState('tenants', ['empresas']),
    ...mapState('auth', ['empresaAtual']),

    permission() {
      return Sequences.SistemaEmpresas.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },

    formTitle() {
      return this.editedIndex === -1 ? 'Nova empresa' : 'Editar empresa'
    },
    allowedToAdd() {
      return this.empresaAtual.system_admin
    },
    items() {
      return this.empresas.results
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    options: {
      handler() {
        this.handleGetEmpresas()
      },
      deep: true,
    },
  },

  async created() {
    if (this.canList) {
      await this.handleGetEmpresas()
    }
  },

  methods: {
    //...mapActions('tenants', ['getEmpresas']),
    ...mapActions('tenants', ['cleanEmpresas', 'deleteEmpresa']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async getSearch() {
      if (this.search.length > 0) {
        this.handleGetEmpresas(this.search.toLocaleUpperCase(), true)
      }
    },
    async handleGetEmpresas(search = '', loading = true) {
      this.cleanEmpresas()
      if (this.empresaAtual.system_admin) {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const page_size = itemsPerPage
        this.loading = loading
        try {
          //const { sortBy, sortDesc } = this.options
          let ordering
          if (
            sortBy &&
            sortBy.length === 1 &&
            sortDesc &&
            sortDesc.length === 1
          ) {
            ordering = this.options.sortBy[0]
            ordering = this.options.sortDesc[0] ? '-' + ordering : ordering
            ordering = ordering.replaceAll('.', '__')
          }
          this.empresas = await TenantsApi.getEmpresas({
            proprietario: this.empresaAtual.public_id,
            page_size: page_size > 0 ? page_size : 10,
            page: page,
            ordering: ordering,
            pesquisa: search,
          })
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.errorMsg = 'Usuário não autorizado.'
          } else {
            this.errorMsg = 'Ocorreu algum erro inesperado.'
          }
          this.snackbar = true
        } finally {
          this.loading = false
        }
      } else {
        this.errorMsg =
          'Empresa atual não possui permissão para listar empresas .'
        this.snackbar = true
      }
    },

    adicionarEmpresa() {
      this.$emit('showForm')
    },

    editItem(item) {
      this.$emit('editar', item)
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save() {
      if (this.editedIndex > -1) {
        this.editPatio(this.editedItem) // REVER.. Editar
      } else {
        this.addPatio(this.editedItem)
      }
      this.close()
    },

    deleteItem(empresa) {
      this.itemToDelete = empresa

      this.setDialogMessage({
        title: 'Confirmar exclusão?',
        message: `Deseja mesmo excluir a empresa ${empresa.trade_name}?`,
      })
    },

    async confirmDelete() {
      try {
        await this.deleteEmpresa(this.itemToDelete)
        this.successMessage(
          `Empresa ${this.itemToDelete.trade_name} excluída com sucesso!`
        )
      } catch (error) {
        this.errorMessage(
          `Erro ao excluir a empresa ${this.itemToDelete.trade_name}: ` + error
        )
      }
    },
  },
}
</script>
