var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',{staticClass:"mt-4"},[(_vm.canList)?_c('v-card-title',[_vm._v(" Filtro "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","single-line":"","hide-details":""},on:{"change":_vm.getSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"server-items-length":_vm.empresas.count,"item-key":"public_id","footer-props":{
        itemsPerPageAllText: ("Todos (" + (_vm.empresas.count) + ")"),
        itemsPerPageOptions: [_vm.pageSize].concat(
          [15, 50, 100, 500, 1000, 2000].filter(function (el) { return el != _vm.pageSize; })
        ),
      },"page":_vm.paginaAtual,"items-per-page":_vm.pageSize,"options":_vm.options},on:{"update:page":[function($event){_vm.paginaAtual=$event},function($event){return _vm.$emit('handleGetEmpresas', $event)}],"update:options":function($event){_vm.options=$event},"update:items-per-page":function($event){return _vm.$emit('handleGetEmpresas', $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Empresas registradas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.canList)?_c('v-icon',{attrs:{"medium":"","title":"Atualizar"},on:{"click":_vm.handleGetEmpresas}},[_vm._v("mdi-refresh")]):_vm._e(),_c('v-spacer'),(_vm.allowedToAdd && _vm.canCreate)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.adicionarEmpresa}},[_vm._v("Nova empresa")]):_vm._e()],1)]},proxy:true},{key:"item.cnpj",fn:function(ref){
      var item = ref.item;
return [(item.cpf)?_c('span',[_vm._v(" "+_vm._s(_vm._f("VMask")(item.cpf,'###.###.###-##'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("VMask")(item.cnpj,'##.###.###/####-##'))+" ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(_vm.canUpdate)?_c('v-icon',{staticClass:"mx-1",attrs:{"title":"Editar Empresa","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.canDelete)?_c('v-icon',{staticClass:"mx-1",attrs:{"title":"Excluir Empresa","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'Colaboradores', params: { empresa: item } }}},[(_vm.canUpdate)?_c('v-icon',{staticClass:"mx-1",attrs:{"title":"Visualizar Colaboradores","small":""}},[_vm._v("mdi-account-multiple")]):_vm._e()],1),_c('confirm-dialog',{on:{"confirm":_vm.confirmDelete}})]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1),_c('v-snackbar',{attrs:{"top":"","color":"error","elevation":"12"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Fechar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }