<template>
  <div class="text-center">
    <v-tabs icons-and-text v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#dados">
        Dados da empresa <v-icon>mdi-home-city</v-icon></v-tab
      >
      <v-tab href="#contatos"> Contatos <v-icon>mdi-phone</v-icon></v-tab>
      <v-tab href="#perfis"> Perfis <v-icon>mdi-file-table-box-multiple</v-icon></v-tab>
      <v-tab href="#anexos"> Anexos <v-icon>mdi-file-table-box-multiple</v-icon></v-tab>
      <v-tab href="#menus"> Acessos <v-icon>mdi-sitemap</v-icon></v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item value="dados">
          <dados-empresa v-model="empresa" @close="close" />
        </v-tab-item>
        <v-tab-item value="contatos">
          <dados-contrato v-model="empresa" @close="close"/>
        </v-tab-item>
        <v-tab-item value="perfis">
          <dados-perfis v-model="empresa" @close="close"/>
        </v-tab-item>
        <v-tab-item value="anexos">
        <etapa-anexos
          ref="etapaAnexos"
          :documentos="documentos"
          :documentosList="empresa.anexos"
          @setNames="setDocs"
          @remove-documento="removeDocumento"
          v-model="empresa"
        ></etapa-anexos>
        </v-tab-item>
        <v-tab-item value="menus">
          <items-menu
            @cancelarEdicao="$emit('cancelarEdicao')"
            :itemsSelected="empresa.menu"
            :empresa="empresa"
            :basicPermissions="empresa.basic_permissions"
            @close="close"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosEmpresa from './DadosEmpresa.vue'
import DadosContrato from './DadosContrato.vue'
import DadosPerfis from './DadosPerfis.vue'
import EtapaAnexos from './EtapaAnexos.vue'
import ItemsMenu from './ItemsMenu.vue'

export default {
  props: {
    empresa: {
      type: Object,
      required: true,
    },
  },
  components: { DadosEmpresa, DadosContrato, DadosPerfis, EtapaAnexos, ItemsMenu },
  data() {
    return {
      tab: null,
      documentosList: [],
      documentos: [],
      documentosNome: [],
    }
  },
  async insereDocumentoNosAnexos(doc) {
      this.documentosList = doc
    },
  methods: {
    close: function () {
      this.$emit('close')
    },
    setDocs(docs) {
      this.documentosNome = docs
      },
     removeDocumento(doc) {
      const index = this.documentosNome.indexOf(doc.attachment_key)
      if (index >= 0) {
        delete this.documentosNome[index]
        this.documentosNome = this.documentosNome.filter(
          (documento) => documento !== null
        )
      }
      if (this.documentosNome.length > 0) {
        this.btnConfirm = false
      } else {
        this.btnConfirm = true
      }
    },
    setDocumentosList(documentos)
    {
      this.$refs.etapaAnexos.setDocumentosList(
          documentos
      )
    },
    async updateDocs(listaDocs) {
      try {
        await this.updateDocumentosVeiculo({
          veiculo: this.novoVeiculo,
          documentos: listaDocs,
        })
        this.$emit('close')
        this.successMessage(
          `Veiculo ${this.novoVeiculo.placa_cavalo} editado com sucesso`
        )
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
  },
}
</script>