<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Empresas" :class="{ tabs: empresaEdita.id }">
          <form-adicionar-empresa
            v-if="showForm || irParaCadastro"
            @addedEmpresa="showForm = false"
            :empresaEdita="empresaEdita"
          />
          <form-editar-empresa
          ref="teste"
            v-else-if="editarEmpresa"
            :empresa="empresaSelecionada"
            @close="editarEmpresa = false"
          />
          <listar-empresas
            v-else
            @showForm="showForm = true"
            @editar="editar"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.tabs {
  .pa-4 {
    padding: 0 !important;

    .cadastroEmpresa {
      padding: 16px;
      margin-top: 32px;
    }
  }
}
</style>

<script>
import ListarEmpresas from '@/components/gerencial/empresas/ListarEmpresas'
import FormAdicionarEmpresa from '@/components/gerencial/empresas/adicionarEmpresa/FormAdicionarEmpresa'
import FormEditarEmpresa from '@/components/gerencial/empresas/editarEmpresa/FormEditarEmpresa'
export default {
  props: ['irParaCadastro'],
  data() {
    return {
      showForm: false,
      editarEmpresa: false,
      page: {
        title: 'Gerenciamento de empresas (Global)',
      },
      breadcrumbs: [
        {
          text: 'Gerenciamento',
          disabled: true,
          to: '/geral/clientes',
        },
        {
          text: 'Empresas',
          disabled: true,
        },
      ],
      empresaSelecionada: {},
      empresaEdita: {},
    }
  },
  components: {
    ListarEmpresas,
    FormAdicionarEmpresa,
    FormEditarEmpresa,
  },
  methods: {
    editar(empresa) {
      // this.$refs.teste.setDocumentosList(
          // this.empresa.anexos
      // )
      this.empresaSelecionada = empresa
      this.editarEmpresa = true
    },
  },
}
</script>

<style></style>
