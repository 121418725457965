<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Informações do contato com a empresa
      </h3>
    </v-card-text>
    <v-card-text class="pa-5 border-bottom">
      <v-form>
        <div class="mt-4">
          <v-data-table
            :headers="headers"
            :items="contatos"
            sort-by="descricao"
            class="border"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Contatos registrados</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="!adicionandoContato"
                  color="primary"
                  dark
                  class="mb-2"
                  :disabled="disabled"
                  @click="adicionandoContato = !adicionandoContato; editandoContato = false; contatoAtual = {}"
                  >Novo Contato</v-btn
                >
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editarContato(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="excluirContato(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:no-data>
              <span>Sem contatos adicionados</span>
            </template>
          </v-data-table>
          <validation-observer ref="observer">
            <v-form v-if="adicionandoContato || editandoContato" class="mt-5">
              <v-card class="pa-5">
                <v-row>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>
                      {{ adicionandoContato ? 'Novo Contato' : editandoContato ? 'Editar contato' : 'Dados do contato' }}
                    </v-toolbar-title>
                  </v-toolbar>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" lg="6" class="pt-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="nome"
                      rules="required"
                    >
                      <v-text-field
                        label="Nome"
                        :error-messages="errors"
                        required
                        v-model="contatoAtual.nome"
                        :disabled="disabled"
                      ></v-text-field>
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="telefone"
                      :rules="{regex: regexTelefone, required: true}"
                    >
                      <v-text-field
                        label="Telefone"
                        :error-messages="errors"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        v-model="contatoAtual.telefone"
                        :disabled="disabled"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" lg="6" class="pt-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <v-text-field
                        label="Email"
                        :error-messages="errors"
                        v-model="contatoAtual.email"
                        :disabled="disabled"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="descrição"
                      rules="required"
                    >
                      <v-text-field
                        label="Descrição"
                        :error-messages="errors"
                        v-model="contatoAtual.descricao"
                        :disabled="disabled"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <div class="pa-5 border-top text-right">
                  <v-btn
                    color="info"
                    class="mr-2 text-capitalize"
                    @click="adicionarContato"
                    :disabled="disabled"
                    >
                      {{ adicionandoContato ? 'Adicionar Contato' : editandoContato ? 'Editar contato' : 'Confirmar' }}
                    </v-btn
                  >
                  <v-btn
                    color="black"
                    class="text-capitalize"
                    dark
                    @click="
                      contatoAtual = {};
                      editandoContato = false;
                      adicionandoContato = false;
                    "
                    >Cancelar</v-btn
                  >
                </div>
              </v-card>
            </v-form>
          </validation-observer>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    disabled: Boolean,
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contatos: [],
      contatoAtual: {},
      editandoContato: false,
      adicionandoContato: false,
      regexTelefone: /(\(?\d{2}\)?\s)?(\d{3,4}\d-\d{4})/,
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'nome',
        },
        { text: 'E-mail', value: 'email' },
        { text: 'Telefone', value: 'telefone' },
        { text: 'Setor Responsável', value: 'descricao' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
    }
  },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),

    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },
    adicionarContato() {
      if (!this.validate()) return this.errorMessage('Preencha corretamente todos os campos!')

      if (Object.prototype.hasOwnProperty.call(this.contatoAtual, 'id')) {
        const index = this.contatos.findIndex(contato => contato.id === this.contatoAtual.id)
        this.contatos.splice(index, 1, this.contatoAtual)
      } else {
        this.contatos.push({ ...this.contatoAtual, id: this.contatos[this.contatos.length - 1] ? this.contatos[this.contatos.length - 1].id + 1 : 1 })
      }
      this.editandoContato = false
      this.adicionandoContato = false
      this.contatoAtual = {}
      this.update()
    },
    editarContato(contato) {
      if (!this.validate()) return this.errorMessage('Preencha corretamente todos os campos!')

      this.contatoAtual = {...contato}
      this.editandoContato = true
    },
    excluirContato(contato) {
      const indiceContato = this.contatos.indexOf(contato)
      this.contatos.splice(indiceContato, 1)
      this.update()
    },
    update() {
      this.$emit(
        'atualizar-contatos',
        JSON.parse(JSON.stringify(this.contatos))
      )
    },
  },
}
</script>

<style>
</style>
