var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-7"},[_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('h3',{staticClass:"subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"},[_vm._v(" Sobre a empresa ")]),(!_vm.novaEmpresa)?_c('span',{staticClass:"red--text"},[_vm._v("Dados editáveis apenas pelo administrador da empresa")]):_vm._e()]),_c('validation-observer',{ref:"observer"},[_c('v-form',{attrs:{"readonly":!_vm.novaEmpresa}},[_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Razão Social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Razão Social","error-messages":errors,"disabled":_vm.disabled,"required":""},model:{value:(_vm.empresa.trade_name),callback:function ($$v) {_vm.$set(_vm.empresa, "trade_name", $$v)},expression:"empresa.trade_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"Nome Fantasia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nome Fantasia","error-messages":errors,"disabled":_vm.disabled,"required":""},model:{value:(_vm.empresa.business_name),callback:function ($$v) {_vm.$set(_vm.empresa, "business_name", $$v)},expression:"empresa.business_name"}})]}}])})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"CNPJ","rules":"required|cpfOuCnpjValido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['##.###.###/####-##', '###.###.###-##']),expression:"['##.###.###/####-##', '###.###.###-##']"}],attrs:{"label":_vm.cpfValidator.isValid(_vm.empresa.cpfOrCnpj)
                    ? 'CPF'
                    : _vm.cnpjValidator.isValid(_vm.empresa.cpfOrCnpj)
                    ? 'CNPJ'
                    : 'Inválido',"readonly":_vm.disabled,"error-messages":errors,"disabled":_vm.disabled},model:{value:(_vm.empresa.cpfOrCnpj),callback:function ($$v) {_vm.$set(_vm.empresa, "cpfOrCnpj", $$v)},expression:"empresa.cpfOrCnpj"}})]}}])}),_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":"IE"},model:{value:(_vm.empresa.ie),callback:function ($$v) {_vm.$set(_vm.empresa, "ie", $$v)},expression:"empresa.ie"}})],1)],1)],1),_c('v-card-text',{staticClass:"pa-5"},[_c('h3',{staticClass:"subtitle blue-grey--text text--darken-2 font-weight-regular"},[_vm._v(" Endereço ")]),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"CEP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['#####-###']),expression:"['#####-###']"}],attrs:{"error-messages":errors,"label":"CEP","required":"","disabled":_vm.disabled},model:{value:(_vm.empresa.zip_code),callback:function ($$v) {_vm.$set(_vm.empresa, "zip_code", $$v)},expression:"empresa.zip_code"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"label":"Estado","required":"","items":_vm.estados,"disabled":_vm.disabled,"item-text":"nome","item-value":"sigla","return-object":""},on:{"change":_vm.handleGetCidades},model:{value:(_vm.empresa.state),callback:function ($$v) {_vm.$set(_vm.empresa, "state", $$v)},expression:"empresa.state"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Cidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"label":"Cidade","required":"","items":_vm.cidades,"disabled":_vm.disabled,"item-text":"nome","item-value":"nome"},model:{value:(_vm.empresa.city),callback:function ($$v) {_vm.$set(_vm.empresa, "city", $$v)},expression:"empresa.city"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Bairro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Bairro","required":"","disabled":_vm.disabled},model:{value:(_vm.empresa.district),callback:function ($$v) {_vm.$set(_vm.empresa, "district", $$v)},expression:"empresa.district"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Endereço","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","label":"Endereço","disabled":_vm.disabled},model:{value:(_vm.empresa.address),callback:function ($$v) {_vm.$set(_vm.empresa, "address", $$v)},expression:"empresa.address"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Número","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","label":"Número","type":"number","disabled":_vm.disabled},model:{value:(_vm.empresa.numero),callback:function ($$v) {_vm.$set(_vm.empresa, "numero", $$v)},expression:"empresa.numero"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Complemento","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Complemento","disabled":_vm.disabled},model:{value:(_vm.empresa.complemento),callback:function ($$v) {_vm.$set(_vm.empresa, "complemento", $$v)},expression:"empresa.complemento"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) #####-####', '(##) ####-####']),expression:"['(##) #####-####', '(##) ####-####']"}],attrs:{"error-messages":errors,"label":"Telefone","required":"","disabled":_vm.disabled},model:{value:(_vm.empresa.phone),callback:function ($$v) {_vm.$set(_vm.empresa, "phone", $$v)},expression:"empresa.phone"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"label":"Gate"},model:{value:(_vm.empresa.gate),callback:function ($$v) {_vm.$set(_vm.empresa, "gate", $$v)},expression:"empresa.gate"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }