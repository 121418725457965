<template>
  <div class="text-center">
    <form-wizard
      @on-complete="submit"
      class="cadastroEmpresa"
      :class="{ hasError }"
      color="#00aa9e"
      :title="null"
      :subtitle="null"
      :start-index="empresaEdita.id ? 0 : 0"
    >
      <tab-content
        title="CNPJ do Cliente"
        icon="ti-user"
        :before-change="validarCPFOrCNPJInformado"
      >
        <etapa-cnpj
          ref="etapaCnpj"
          v-model="cpfOrCnpj"
          :cnpjProp="cpfOrCnpj"
          :disabled="empresaJaExistente"
        ></etapa-cnpj>
      </tab-content>

      <tab-content
        title="Informações Gerais"
        icon="ti-settings"
        :before-change="validarEmpresa"
      >
        <etapa-dados-empresa
          v-model="cliente"
          :disabled="empresaJaExistente"
          :novaEmpresa="!empresaJaExistente"
          ref="etapaDadosEmpresa"
        ></etapa-dados-empresa>
      </tab-content>

      <tab-content
        title="Administrador da empresa"
        icon="ti-user"
        :before-change="validarUsuario"
      >
        <form-fields-adicionar-usuario
          :disabled="usuarioJaExistente"
          ref="etapaDadosUsuario"
          @close="$emit('close')"
          @save="$emit('save')"
          :hasButton="false"
          cadastroAdminEmpresa
        />
      </tab-content>

      <tab-content title="Informações do contato" icon="ti-check">
        <etapa-dados-contato
          v-model="cliente"
          :disabled="empresaEdita.id ? true : false"
          @atualizar-contatos="atualizarContatos"
          ref="etapaDadoscontato"
        ></etapa-dados-contato>
      </tab-content>
      <v-btn color="black" dark slot="prev">
        <i class="mdi mdi-chevron-left mr-1"></i>
        Anterior
      </v-btn>
      <v-btn color="info" slot="next">
        Próximo
        <i class="mdi mdi-chevron-right ml-1"></i>
      </v-btn>
      <v-btn
        color="success"
        slot="finish"
        :disabled="empresaEdita.id ? true : false"
      >
        Finalizar
        <i class="mdi mdi-bookmark-check ml-1"></i>
      </v-btn>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { cnpj, cpf } from 'cpf-cnpj-validator'

import EtapaCnpj from '@/components/cadastros/geral/EtapaCnpj'
import EtapaDadosEmpresa from '@/components/gerencial/empresas/adicionarEmpresa/EtapaDadosEmpresa'
import FormFieldsAdicionarUsuario from '@/components/gerencial/colaboradores/adicionarUsuario/FormFieldsAdicionarUsuario.vue'

import EtapaDadosContato from '@/components/gerencial/empresas/adicionarEmpresa/EtapaDadosContato'
import TenantsApi from '@/services/tenants'
import UsuarioApi from '@/services/usuario/index'

import { mapState, mapActions } from 'vuex'
import { jsonp } from 'vue-jsonp'

export default {
  props: ['empresaEdita'],
  data() {
    return {
      tab: null,
      title: 'Adicionar Clientes',
      cliente: {
        cpfOrCnpj: '',
        business_name: '',
        trade_name: '',
        address: '',
        district: '',
        city: '',
        zip_code: '',
        state: {},
        phone: '',
      },
      hasError: false,
      cpfOrCnpj: '',
      empresaJaExistente: false,
      usuarioJaExistente: false,
      contatos: [],
      dadosUsuario: {},
    }
  },

  components: {
    FormWizard,
    TabContent,
    EtapaCnpj,
    EtapaDadosEmpresa,
    FormFieldsAdicionarUsuario,
    EtapaDadosContato,
  },
  methods: {
    ...mapActions('gerencial', ['addColaborador']),
    ...mapActions('tenants', ['adicionarEmpresaCliente', 'addEmpresa']),
    ...mapActions('usuario', ['addUsuario']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    async validarCPFOrCNPJInformado() {
      if (this.empresaJaExistente) {
        return true
      }

      const refToValidate = this.$refs['etapaCnpj']
      if (refToValidate.validate()) {
        return this.verificarCPFOrCNPJExistente(true)
      }
      this.hasError = true
      return false
    },
    async validarEmpresa() {
      if (this.empresaJaExistente) {
        return true
      }

      if (!this.empresaAtual.public_id) return

      if (!this.$refs['etapaDadosEmpresa'].validate()) {
        this.errorMessage('Corrija os campos inválidos!')

        this.$nextTick(() => {
          this.hasError = true
        })
        return false
      }

      return true
    },

    async submit() {
      let empresaCadastrada = {}

      try {
        if (!this.usuarioJaExistente) {
          if (this.dadosUsuario.user && this.dadosUsuario.user.cpf) {
            this.dadosUsuario.user.cpf = cpf.strip(this.dadosUsuario.user.cpf)
          }
          if (this.dadosUsuario.user && this.dadosUsuario.user.cnpj) {
            this.dadosUsuario.user.cnpj = cnpj.strip(
              this.dadosUsuario.user.cnpj
            )
          }
        }

        if (!this.empresaJaExistente) {
          const empresaDados = {
            ...this.cliente,
            tenant_criador: this.empresaAtual.public_id,
            contatos: this.contatos,
            admin: this.dadosUsuario,
            state: this.cliente.state.sigla,
          }

          if (cnpj.isValid(this.cliente.cpfOrCnpj)) {
            empresaDados['cnpj'] = cnpj.strip(this.cliente.cpfOrCnpj)
          } else if (cpf.isValid(this.cliente.cpfOrCnpj)) {
            empresaDados['cpf'] = cpf.strip(this.cliente.cpfOrCnpj)
          }

          const dados = await this.addEmpresa(empresaDados)

          empresaCadastrada = { ...dados }
          this.empresaJaExistente = true
          this.hasError = false
          this.successMessage('Empresa cadastrada com sucesso!')
        }
      } catch (err) {
        this.hasError = true
        this.errorMessage(
          'Erro ao cadastrar a empresa: ' + JSON.stringify(err.response.data)
        )
        return false
      }

      try {
        this.adicionarEmpresaCliente({
          empresa: this.empresaAtual.public_id,
          cliente: empresaCadastrada.public_id,
          contatos: [],
        })
        this.successMessage('Cliente cadastrado com sucesso!')
        this.$emit('addedEmpresa')
      } catch (err) {
        this.errorMessage(
          'Ocorreu um erro ao cadastrar o cliente...' +
            JSON.stringify(err.response.data)
        )
        return false
      }
    },

    atualizarContatos(contatos) {
      this.contatos = [...contatos]
      return
    },
    async verificarCPFOrCNPJExistente(isValid) {
      if (this.empresaJaExistente) {
        return true
      }
      if (isValid) {
        let empresaCadastrada = []

        if (cnpj.isValid(this.cpfOrCnpj)) {
          empresaCadastrada = await TenantsApi.getTenantByCPFOrCNPJ({
            cnpj: cnpj.strip(this.cpfOrCnpj),
          })
        } else if (cpf.isValid(this.cpfOrCnpj)) {
          empresaCadastrada = await TenantsApi.getTenantByCPFOrCNPJ({
            cpf: cpf.strip(this.cpfOrCnpj),
          })
        }

        this.cliente.cpfOrCnpj = this.cpfOrCnpj

        if (empresaCadastrada.length) {
          this.errorMessage('Empresa já cadastrada.')
          this.cpfOrCnpj = ''
          return false
        } else {
          const dadosEmpresaReceita = await this.fetchDadosEmpresaReceita()
          this.syncDadosClienteComDadosReceita(dadosEmpresaReceita)
          return true
        }
      }
    },

    syncDadosClienteComDadosReceita(dadosEmpresaReceita) {
      if (dadosEmpresaReceita) {
        this.cliente.business_name = dadosEmpresaReceita.fantasia
        this.cliente.trade_name = dadosEmpresaReceita.nome
        this.cliente.address = dadosEmpresaReceita.logradouro
        this.cliente.district = dadosEmpresaReceita.bairro
        this.cliente.city = dadosEmpresaReceita.municipio
        this.cliente.zip_code = dadosEmpresaReceita.cep
        this.cliente.state = { sigla: dadosEmpresaReceita.uf }
        this.cliente.phone = dadosEmpresaReceita.telefone
          ? dadosEmpresaReceita.telefone.split('/')[0].trim()
          : ''
      }
    },

    async fetchDadosEmpresaReceita() {
      const { cpfOrCnpj } = this.cliente

      if (cnpj.isValid(cpfOrCnpj)) {
        const qCnpj = await jsonp(
          `https://www.receitaws.com.br/v1/cnpj/${cnpj.strip(cpfOrCnpj)}`
        )
        return qCnpj
      }
    },

    dadosEditar() {
      if (this.empresaEdita.id) {
        Object.assign(this.cliente, this.empresaEdita)
        this.cliente.zip_code = this.empresaEdita.zip_code
        this.cpfOrCnpj = this.empresaEdita.cnpj
      }
    },

    async validarUsuario() {
      if (this.usuarioJaExistente) return true

      const refToValidate = this.$refs['etapaDadosUsuario']

      if (!refToValidate.validate()) {
        this.$nextTick(() => {
          this.hasError = true
        })
        return false
      }

      let usuario = await refToValidate.save(false)
      if (usuario) {
        this.dadosUsuario = usuario
      } else {
        return false
      }

      return true
    },
    async verificaEmailCadastrado(email) {
      const response = await UsuarioApi.getUsuarioByParams({ email })

      if (!response) return false

      if (Object.keys(response).length > 0) {
        return true
      }
      return false
    },
  },
  created() {
    this.dadosEditar()
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),

    erroCNPJ() {
      const errors = []
      if (!this.$v.cnpj.$dirty) return errors
      !this.$v.cnpj.cnpjValido && errors.push('CNPJ inválido')
      !this.$v.cnpj.required && errors.push('Campo obrigatório')
      return errors
    },
  },
}
</script>

<style lang="scss">
.hasError .wizard-navigation .wizard-nav {
  .active a .wizard-icon-circle > div {
    background-color: transparent !important;
    i {
      color: #fff !important;
    }
  }
}

.cadastroEmpresa .wizard-navigation .wizard-nav {
  .active {
    a .wizard-icon-circle > div {
      background-color: transparent !important;
      i {
        color: #00aa9e;
      }
    }
  }
  li:not(.active ~ li):not(.active) {
    .wizard-icon-circle {
      background-color: #00aa9e;
      i {
        color: white;
      }
    }
    span {
      color: #00aa9e;
    }
  }
}
</style>
