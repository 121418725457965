var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-7"},[_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('h3',{staticClass:"subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"},[_vm._v(" Sobre a empresa ")])]),_c('validation-observer',{ref:"observer"},[_c('v-form',[_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Nome Fantasia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nome Fantasia","error-messages":errors,"required":""},model:{value:(_vm.empresa.business_name),callback:function ($$v) {_vm.$set(_vm.empresa, "business_name", $$v)},expression:"empresa.business_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"Razão Social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Razão Social","error-messages":errors},model:{value:(_vm.empresa.trade_name),callback:function ($$v) {_vm.$set(_vm.empresa, "trade_name", $$v)},expression:"empresa.trade_name"}})]}}])})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"CNPJ/CPF","rules":"required|cnpjValido|cnpjNaoEmpresaAtual"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['##.###.###/####-##', '###.###.###-##']),expression:"['##.###.###/####-##', '###.###.###-##']"}],attrs:{"label":"CNPJ/CPF","error-messages":errors},model:{value:(_vm.empresa.cnpj),callback:function ($$v) {_vm.$set(_vm.empresa, "cnpj", $$v)},expression:"empresa.cnpj"}})]}}])}),_c('v-text-field',{attrs:{"label":"IE"},model:{value:(_vm.empresa.ie),callback:function ($$v) {_vm.$set(_vm.empresa, "ie", $$v)},expression:"empresa.ie"}})],1)],1)],1),_c('v-card-text',{staticClass:"pa-5"},[_c('h3',{staticClass:"subtitle blue-grey--text text--darken-2 font-weight-regular"},[_vm._v(" Endereço ")]),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Endereço"},model:{value:(_vm.empresa.address),callback:function ($$v) {_vm.$set(_vm.empresa, "address", $$v)},expression:"empresa.address"}})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Número","rules":"max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Número","type":"number"},model:{value:(_vm.empresa.numero),callback:function ($$v) {_vm.$set(_vm.empresa, "numero", $$v)},expression:"empresa.numero"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Bairro"},model:{value:(_vm.empresa.district),callback:function ($$v) {_vm.$set(_vm.empresa, "district", $$v)},expression:"empresa.district"}})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Complemento","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Complemento"},model:{value:(_vm.empresa.complemento),callback:function ($$v) {_vm.$set(_vm.empresa, "complemento", $$v)},expression:"empresa.complemento"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Cidade"},model:{value:(_vm.empresa.city),callback:function ($$v) {_vm.$set(_vm.empresa, "city", $$v)},expression:"empresa.city"}})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['#####-###']),expression:"['#####-###']"}],attrs:{"label":"CEP"},model:{value:(_vm.empresa.zip_code),callback:function ($$v) {_vm.$set(_vm.empresa, "zip_code", $$v)},expression:"empresa.zip_code"}})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('v-select',{attrs:{"label":"Estado","items":_vm.estados,"item-text":"nome","item-value":"sigla"},model:{value:(_vm.empresa.state),callback:function ($$v) {_vm.$set(_vm.empresa, "state", $$v)},expression:"empresa.state"}})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) #####-####', '(##) ####-####']),expression:"['(##) #####-####', '(##) ####-####']"}],attrs:{"label":"Telefone"},model:{value:(_vm.empresa.phone),callback:function ($$v) {_vm.$set(_vm.empresa, "phone", $$v)},expression:"empresa.phone"}})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"label":"Gate"},model:{value:(_vm.empresa.gate),callback:function ($$v) {_vm.$set(_vm.empresa, "gate", $$v)},expression:"empresa.gate"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('buttons-cadastro',{on:{"close":_vm.close,"save":_vm.salvarEmpresa}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }